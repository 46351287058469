// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import DoorControl from './components/DoorControl';
import PINManagement from './components/PINManagement';
import PINInput from './components/PINInput';
import Login from './components/Login';
import Header from './components/Header';
import { AuthProvider, useAuth } from './hooks/AuthContext';
import '@blueprintjs/core/lib/css/blueprint.css';

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Main />
      </AuthProvider>
    </Router>
  );
};

const Main = () => {
  const { isAuthenticated } = useAuth();

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={isAuthenticated ? <Navigate to="/door-control"/> : <Navigate to="/admin-login"/>} />
        <Route path="/enter-pin" element={isAuthenticated ? <Navigate to="/door-control"/> : <PINInput />} />
        <Route path="/door-control" element={isAuthenticated ? <DoorControl /> : <Navigate to="/admin-login"/>} />
        <Route path="/pin-management" element={isAuthenticated ? <PINManagement /> : <Navigate to="/admin-login" />} />
        <Route path="/admin-login" element={isAuthenticated ? <Navigate to="/door-control" /> :  <Login />} /> {/* New route */}
      </Routes>
    </>
  );
};

export default App;
