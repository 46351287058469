import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Card,
  Elevation,
  Checkbox,
  InputGroup,
  Button,
  Callout,
  HTMLTable,
  Label
} from '@blueprintjs/core';

const CreatePIN = () => {
  const [isOneTime, setIsOneTime] = useState(true);
  const [expiryDays, setExpiryDays] = useState(0);
  const [pins, setPins] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchPins = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://dry-refuge-10597-1582e455c3e5.herokuapp.com/pin/list', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setPins(response.data);
    } catch (error) {
      setError('Error fetching PINs');
    } finally {
      setLoading(false);
    }
  };

  const handleCreatePIN = async () => {
    setLoading(true);
    try {
      await axios.post('https://dry-refuge-10597-1582e455c3e5.herokuapp.com/pin/create', {
        isOneTime,
        expiryDays
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      fetchPins(); // Refresh the list of PINs
    } catch (error) {
      setError('Error creating PIN');
    } finally {
      setLoading(false);
    }
  };

  const handleDeletePIN = async (pinCode) => {
    setLoading(true);
    try {
      await axios.delete(`https://dry-refuge-10597-1582e455c3e5.herokuapp.com/pin/delete/${pinCode}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      fetchPins(); // Refresh the list of PINs
    } catch (error) {
      setError('Error deleting PIN');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPins();
  }, []);

  return (
    <div style={{ padding: '20px' }}>
      <Card interactive={true} elevation={Elevation.TWO} style={{ maxWidth: '800px', margin: '0 auto' }}>
        <h2>Create PIN</h2>
        {error && <Callout intent="danger">{error}</Callout>}
        <div style={{ marginBottom: '20px' }}>
          <Checkbox
            checked={isOneTime}
            onChange={(e) => setIsOneTime(e.target.checked)}
            label="One-time PIN"
          />
          <Label>
          Expiry Days:
          <InputGroup
            type="number"
            value={expiryDays}
            onChange={(e) => setExpiryDays(Number(e.target.value))}
            placeholder="Expiry Days"
            style={{ marginTop: '10px' }}
          />
          </Label>
          <Button
            text="Create PIN"
            intent="primary"
            onClick={handleCreatePIN}
            loading={loading}
            style={{ marginTop: '10px' }}
          />
        </div>

        <h3>Existing PINs</h3>
        <HTMLTable striped bordered condensed style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>PIN Code</th>
              <th>One-Time</th>
              <th>Expiry Date</th>
              <th>Created At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {pins.map((pin) => (
              <tr key={pin.pin_code}>
                <td>{pin.pin_code}</td>
                <td>{pin.is_one_time ? 'Yes' : 'No'}</td>
                <td>{pin.expiry_date ? new Date(pin.expiry_date).toLocaleString() : 'N/A'}</td>
                <td>{new Date(pin.created_at).toLocaleString()}</td>
                <td>
                  <Button 
                    text="Delete" 
                    intent="danger" 
                    onClick={() => handleDeletePIN(pin.pin_code)}
                    loading={loading}
                    minimal
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </HTMLTable>
      </Card>
    </div>
  );
};

export default CreatePIN;
