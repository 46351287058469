import React, { useState } from 'react';
import axios from 'axios';
import {
  Card,
  Elevation,
  InputGroup,
  Button,
  FormGroup,
  Intent,
  Callout
} from '@blueprintjs/core';

const PINInput = () => {
  const [pin, setPin] = useState('');
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    setMessage('');

    try {
      const response = await axios.post('https://dry-refuge-10597-1582e455c3e5.herokuapp.com/door/open-with-pin', { pin });
      
      if (response.status === 200) {
        setMessage('Opening door for 5 seconds');
        // Optionally, reset the state after 5 seconds
        setTimeout(() => {
          setMessage('');
        }, 5000);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError('Invalid or expired PIN');
      } else {
        setError('An error occurred. Please try again.');
      }
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Card interactive={true} elevation={Elevation.TWO} style={{ width: '400px', padding: '20px' }}>
        <h2>Enter PIN</h2>
        {error && <Callout intent={Intent.DANGER}>{error}</Callout>}
        {message && <Callout intent={Intent.PRIMARY}>{message}</Callout>}
        <form onSubmit={handleSubmit}>
          <FormGroup label="PIN" labelFor="pin-input">
            <InputGroup
              id="pin-input"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              placeholder="Enter your PIN"
              large
              disabled={isProcessing}
            />
          </FormGroup>
          <Button
            text="Submit"
            type="submit"
            intent="primary"
            loading={isProcessing}
            style={{ marginTop: '10px' }}
          />
        </form>
      </Card>
    </div>
  );
};

export default PINInput;
