// Header.js
import React from 'react';
import { useAuth } from '../hooks/AuthContext';
import { Navbar, NavbarGroup, NavbarHeading, Button } from '@blueprintjs/core';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();


  return (
    <Navbar>
      <NavbarGroup align="left">
        <NavbarHeading>27 W 8th St</NavbarHeading>
        {isAuthenticated ? (
          <>
            <Button className="bp3-minimal" icon="home" text="Door Control" onClick={() => navigate('/door-control')} />
            <Button className="bp3-minimal" icon="cog" text="PIN Management" onClick={() => navigate('/pin-management')} />
            <Button className="bp3-minimal" icon="log-out" text="Logout" onClick={logout} />
          </>
        ) : (
          <>
            <Button className="bp3-minimal" icon="log-in" text="Admin Login" onClick={() => navigate('/admin-login')} />
            <Button className="bp3-minimal" icon="key" text="Enter PIN" onClick={() => navigate('/enter-pin')} />
          </>
        )}
      </NavbarGroup>
    </Navbar>
  );
};

export default Header;
