import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Card, Elevation, Callout, Intent, Spinner } from '@blueprintjs/core';

const DoorControl = () => {
    const [doorStatus, setDoorStatus] = useState('Closed');
    const [error, setError] = useState('');
    const [isDoorOpen, setIsDoorOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchDoorStatus = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://dry-refuge-10597-1582e455c3e5.herokuapp.com/door/status', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setDoorStatus(response.data.isOpen ? 'Open' : 'Closed');
            setIsDoorOpen(response.data.isOpen);
        } catch (err) {
            setError('Failed to fetch door status');
        } finally {
            setLoading(false);
        }
    };

    const openDoor = async () => {
        setLoading(true);
        try {
            await axios.post('https://dry-refuge-10597-1582e455c3e5.herokuapp.com/door/open', {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setDoorStatus('Opening door...');
            setIsDoorOpen(true);
            setTimeout(fetchDoorStatus, 5000);
        } catch (err) {
            setError('Failed to open door');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDoorStatus();
    }, []);

    return (
        <div style={{ padding: '20px' }}>
            <Card interactive={true} elevation={Elevation.TWO} style={{ maxWidth: '400px', margin: '0 auto' }}>
                <h2>Door Control</h2>
                {loading && <Spinner />}
                <p>Door Status: {doorStatus}</p>
                <Button 
                    text="Open Door"
                    intent={Intent.PRIMARY}
                    onClick={openDoor}
                    disabled={isDoorOpen || loading}
                    loading={loading}
                />
                {error && <Callout intent={Intent.DANGER} style={{ marginTop: '10px' }}>{error}</Callout>}
            </Card>
        </div>
    );
};

export default DoorControl;
