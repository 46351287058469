import React, { useState } from 'react';
import { useAuth } from '../hooks/AuthContext';
import {
  Card,
  Elevation,
  InputGroup,
  Button,
  FormGroup,
  Intent,
  Callout
} from '@blueprintjs/core';

const LoginPage = () => {
  const { login } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await login(username, password);
    } catch (err) {
      setError('Login failed. Please check your username and password.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Card interactive={true} elevation={Elevation.TWO} style={{ width: '400px', padding: '20px' }}>
        <h2>Admin Login</h2>
        {error && <Callout intent={Intent.DANGER}>{error}</Callout>}
        <form onSubmit={handleSubmit}>
          <FormGroup label="Username" labelFor="username">
            <InputGroup
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter your username"
              large
            />
          </FormGroup>
          <FormGroup label="Password" labelFor="password">
            <InputGroup
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              large
            />
          </FormGroup>
          <Button
            text="Login"
            type="submit"
            intent="primary"
            loading={loading}
            style={{ marginTop: '10px' }}
          />
        </form>
      </Card>
    </div>
  );
};

export default LoginPage;
